import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CJournalText,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CSingleImg02,
  CChapterHeading,
  CTileImg,
  CLabelTextList,
  AssetImage,
} from "../../../components/_index";
import '../../../assets/_sass/page/journal/journal11.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル 京都梅小路",
          date: "2024年07月05日",
          title: (
            <>
              レトロと自然と歴史を歩く、
              <br className="u_pc"/>
              大人の“穴場エリア”京都梅小路へ夫婦旅
            </>
          ),
          text: "ザ ロイヤルパークホテル 京都梅小路",
          img: {
            src: "/assets/images/journal/article11/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article11/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                <p>
                  夏の京都の魅力は、涼やかで青々とした緑と風情ある石畳の街並み、そして水辺にといろいろ思い浮かぶ。
                  ゆったり京都の夏を楽しみたいけれども、どこも観光客でいっぱい…？いいや、そんなことはない。
                  <br className="u_sp"/>
                  今回は大人の“穴場エリア”京都梅小路で、ふらりと歩いて夫婦水入らずの旅を。ザ ロイヤルパークホテル 京都梅小路からめぐってみた。
                </p>
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span className="u_pr0_pc">京都駅から1駅!</span>
                <span>"穴場エリア"京都梅小路</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「ねえ。京都行かない？」
                  </p>
                  <p>
                    妻のそんな提案から始まった今回の京都旅。
                    どこかのんびりできる場所はないか…そんな時に知ったのが、京都梅小路エリアだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter01_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  京都梅小路はもともと西本願寺・東本願寺の門前町として発展した場所。
                  明治から大正にかけて貨物列車の往来が盛んになり、京都市電が開業するなど、多くの人々で賑わったという。
                </>
              ),
            }}
          />
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon01.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon01__sp.png",
              }}
            />
          </figure>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    梅小路エリアに最も近い JR 梅小路京都西駅は2019年に開業したばかりの新しい駅。
                    駅周辺には京都市初の本格的な水族館『京都水族館』や日本最大級の鉄道博物館『京都鉄道博物館』など観光スポットが豊富だ。最近では新しい飲食店も増え始め、新旧のショップや飲食店が立ち並ぶ商店街もあるらしい。
                    <br />
                    山陰本線（嵯峨野線）で京都駅からもたった1駅。そのまま北へ向かえば、嵯峨・嵐山など人気の観光エリアにもアクセスできる。
                  </p>
                  <p>
                    まだチェックインまでは時間があるから、荷物をホテルに預けて梅小路公園を散歩することに。
                  </p>
                </>
              ),
            }}
          />
          <div className="journal11Wrapper">
            <div className="journal11Img">
              <div className="journal11ImgItem">
                <AssetImage src="/assets/images/journal/article11/img_chapter01_02.png" alt="" />
              </div>
              <div className="journal11ImgItem vertical">
                <AssetImage src="/assets/images/journal/article11/img_chapter01_03.png" alt="" />
              </div>
            </div>
            <p className="journal11Caption">公園内では『京都音楽博覧会』などイベントも定期的に開催されている。</p>
          </div>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    大型都市公園ということでかなりの広さを誇る梅小路公園は、美しい日本庭園を望む『朱雀の庭』や復元型ビオトープ『いのちの森』などいくつもの憩いの場がある。広々とした芝生広場では地元の人だろうか、子どもたちと一緒にピクニックを楽しむ人の姿が。緑のシャワーを存分に味わいつつ、せせらぎを鏡に反射する美しい緑に息を呑む。
                  </p>
                  <p>
                    「蒸し暑い気候だけど、不思議と公園の中は涼しいわね」
                    <br />
                    「緑が気持ちいいな」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter01_04.png",
              alt: "",
            }}
            caption={<>公園内からは近くの京都鉄道博物館から運行するSLスチーム号が走る姿が見られる。</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  公園をぐるりと一周して広場に出ると、レトロな可愛らしい電車を見つけた。
                  ここは、市電ひろばと呼ばれるスポットで、ちょっとした休憩ができるカフェがあるようだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter01_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  大正時代に実際に活躍していた路面電車の車体を改造したカフェはレトロ感たっぷり。
                  車内は木造の床や温かみのある照明、当時を思わせるかのように壁に飾られた写真までまるでタイムスリップしたような気分だ。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article11/img_chapter01_06.png",
                  alt: "",
                },
                caption: (
                  <>
                    テイクアウトはもちろん、車内での飲食も可能。
                  </>
                  )
                },
              {
                img: {
                  src: "/assets/images/journal/article11/img_chapter01_07.png",
                  alt: "",
                },
                caption: (
                  <>
                    電車のつりかわに見立てた「カタカタつりわぱん」はお土産にもぴったり。
                  </>
                  )
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「京都に来て公園で散歩っていうのも悪くないね」
                  </p>
                  <p>
                    すっかりリフレッシュした気分で散歩を終えて、お次は街へと繰り出す。
                  </p>
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">梅小路公園</h3>
            <p>
              住所：京都市下京区観喜寺町56-3
              <br />
              HP：
              <a
                href="https://www.kyoto-ga.jp/umekouji/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                https://www.kyoto-ga.jp/umekouji/index.html
              </a>
            </p>
          </CQuoteBox>
          <CQuoteBox>
            <h3 className="c_headingLv3">市電カフェ</h3>
            <p>
              住所：京都市電ひろば（梅小路公園内）
              <br />
              Instagram：
              <a
                href="https://www.instagram.com/shidenhiroba_kyoto/"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                @shidenhiroba_kyoto
              </a>
            </p>
          </CQuoteBox>
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon02.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon02__sp.png",
              }}
            />
            </figure>
            <CJournalText
            data={{
              text: (
                <>
                <p>
                  「ちょっと行ってみたい店があるんだ」
                </p>
                <p>
                そう言って向かったのは、七条通と七本松通の交差点に位置するワインショップ「WineRoom17」。夫婦の共通の趣味であるワインを楽しむため、地元のワインショップを事前に調べていたのだ。
                </p>
                <p>
                  店の扉を開くと出迎えてくれたのはオーナーの伊藤さん。
                </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter01_08.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「棚には国ごとにワインを並べています。白、赤、スパークリングなどなど。お好みの味を言ってもらえたらおすすめもできますよ」
                  </p>
                  <p>
                    国内外問わずさまざまな地域のワインを揃え、伊藤さん自ら良いと思うものだけを取り扱っているそう。街のワインショップらしくお客さんのほとんどは近隣に住む人なのだとか。
                  </p>
                  <p>
                    「あの、実はホテルでこの後、飲みたいんです」
                  </p>
                  <p>
                    そう伝えると、持ってきてくれたのは京都のワイナリー 丹波ワインのスパークリングワイン。1本ずつ手で瓶詰めしているらしく、500mlとほど良い飲みきりサイズなのも嬉しい。
                    やはり暑い夏にはスパークリングがぴったりだ。おつまみとしてチーズセットも買っていこう。
                   </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter01_09.png",
              alt: "",
            }}
            caption={<>店内でその日開いているワインをグラス1杯から飲むこともできる。気軽に飲みにきてもらえたらと、オーナーの伊藤さん。</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  せっかく京都に来たのだから、京都のワインをいただくのが旅の楽しみ。
                  <br />今宵の晩酌が楽しみだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter01_10.png",
              alt: "",
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">Wine Room17</h3>
            <p>
              住所：京都市下京区朱雀北ノ口町23-1
              <br />
              Instagram：
              <a
                href="https://www.instagram.com/wineroom_17/"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                @wineroom_17
              </a>
            </p>
          </CQuoteBox>
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span className="u_pr0_pc">和の趣とモダンの融合が</span>
                <span>心地よいホテル</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon03.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon03__sp.png",
              }}
            />
          </figure>
          <CJournalText
            data={{
              text: (
                <>
                  今回宿泊するザ ロイヤルパークホテル 京都梅小路は、梅小路京都西駅から徒歩約1分とアクセスがとてもいい。  
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter02_01.png",
              alt: "",
            }}
            caption={<>玄関入り口には梅小路という立地にちなんで梅をモチーフにした「ねじり梅」が。紅梅色の暖簾が目印。</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  広々としたロビーは、庭園の景色を切り取った窓をテーマに京都らしいしつらえを思わせる装飾が随所に配されている。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter02_02.png",
              alt: "",
            }}
            caption={<>ロビーと同じ1Fにはコンビニもある。ロビーから直通で行けるので、急な買い物にも便利だ。</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  どこか旅館らしさも感じさせるけれども、機能的でモダンなホテル。京都らしさを感じたいけれど、快適に過ごしたい。
                  そんな願いを叶えてくれる、まさに大人のコンパクトな旅にぴったりだ。大浴場も完備しているらしく、旅の疲れをゆっくりと癒せるのもまたいい。
                </>
              ),
            }}
          />
          <div className="journal11Wrapper">
            <div className="journal11Img journal11Img__reverse">
              <div className="journal11ImgItem ">
                <AssetImage src="/assets/images/journal/article11/img_chapter02_04.png" alt="" />
              </div>
              <div className="journal11ImgItem vertical vertical__reverse">
                <AssetImage src="/assets/images/journal/article11/img_chapter02_03.png" alt="" />
              </div>
            </div>
            <p className="journal11Caption">ロビー天井には「庭の木漏れ日」を模した作品が。ロビーエリアの腰掛けスペースには枯山水のようなモチーフもあり、ホテル内はアートで溢れている。</p>
          </div>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    チェックインを終えて早速客室へ。
                    少し広めの部屋がいいねと今回は夫婦ふたりの意見が合いコーナーデラックス ツインの部屋を選択。嬉しいことに今回の客室からは京都タワーを臨むことができる。コンパクトながらも縦格子や障子など和を感じさせる要素とモダンさがミックスされている。
                  </p>
                  <p>
                    「ねえ、大浴場もあるみたい。私さっそく行ってみようかしら」
                  </p>
                  <p>
                   窓の外の京都タワーをぼんやり眺めている間に、妻がテキパキと支度をして大浴場へ。旅先で足を伸ばしてゆっくり風呂に入れるのはいい。何から何までかゆいところに手が届くところが、心地いいホテルの証だ。
                  </p>
                  </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter02_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「さ、乾杯でもしようか」
                  </p>
                  <p>
                    大浴場から戻り疲れが取れたのか、スッキリした表情の妻に声をかける。チーズやナッツなどおつまみも準備して、先ほど購入したワインを開ける。
                  </p>
                  <p>
                    「京都にもワイナリーがあるなんて知らなかったな」
                    <br />
                    「京都のお酒を京都でいただく。だからいつもよりも美味しく感じられるのかもね」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter02_06.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  部屋で気兼ねなく、そして手軽にお酒を楽しむのも大人の旅。
                  <br />
                  さて、明日は早朝から予定を入れている。今日は早めに就寝して明日に備えるとしよう。
                </>
              ),
            }}
          />
          <h2 className="c_headingLv3 u_tac">
            【Pick Up!】
            <br />
            幻想的な夜の水族館を歩く、
            <br className="u_sp"/>
            京都水族館
          </h2>
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter02_07.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ホテルから徒歩で約5分の場所に位置する京都水族館では 2024年7月27日より夜限定の演出により幻想的な水族館を楽しめる期間限定イベント「夜のすいぞくかん」を開催。
                  <br className="u_pc"/>
                  日中には見られない海のいきものの姿を眺める、貴重な体験を楽しんでみては？
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">京都水族館「夜のすいぞくかん」</h3>
            <p>
              住所：京都市下京区観喜寺町35-1（梅小路公園内）
              <br />
              開催：2024年7月27日（土）～8月25日（日）
              <br />
              時間：17:00～20:00
              <br />
              HP：
              <a
                href="https://www.kyoto-aquarium.com/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                https://www.kyoto-aquarium.com/index.html
              </a>
              <br />
              Instagram：
              <a
                href="https://www.instagram.com/kyoto_aquarium/"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                @kyoto_aquarium
              </a>
            </p>
          </CQuoteBox>
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span className="u_pr0_pc">食べて歩いて、また食べて。</span>
                <span>充実の2日目</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
        <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon04.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon04__sp.png",
              }}
            />
          </figure>
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    うすら明るい早朝5:00、早起きして京都市中央市場へやってきた。
                    ここは日本で初めて開設された中央卸売市場で、青果物・水産物・加工商品などさまざまな食材が売り買いされている。まさに、京都の食文化を支えている場所だ。
                  </p>
                  <p>
                    “カラン カラン カラン”
                  </p>
                  <p>
                    大きな鐘の音が聞こえてくると、一斉に人が集まりせりがスタート。軽快な掛け声と共にどんどんせりが進んでいく。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_02.png",
              alt: "",
            }}
            caption={<>2023年にリニューアルした水産棟の2Fは見学エリア。見学可能な水産品のせりの時間は5:20から。</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  見学エリアには海の生態系や市場の仕事、せりの仕組みなどが知れる展示も。市場の知識だけでなく、京都の食文化を学ぶこともできる。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article11/img_chapter03_03.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article11/img_chapter03_04.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  展示を見終えたら時刻は6:30過ぎ。
                  <br />
                  お腹が空いたので、ホテルへ戻り楽しみにしていた朝食を取ることに。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">京都市中央市場</h3>
            <p>
              住所：京都市下京区朱雀分木町80
              <br />
              HP：
              <a
                href="https://www.kyoto-ichiba.jp"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                https://www.kyoto-ichiba.jp
              </a>
              <br />
              Instagram：
              <a
                href="https://www.instagram.com/kyoto_ichiba/"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                @kyoto_ichiba
              </a>
            </p>
          </CQuoteBox>
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon05.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon05__sp.png",
              }}
            />
          </figure>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ホテル1Fにあるレストラン「梅ごろも」では、和洋どちらのジャンルも楽しめるブッフェスタイルの朝食が食べられる。レストラン奥には出来立てのオムレツやプレスサンドなどを提供するライブキッチンも完備されている。
                  </p>
                  <p>
                    「プレスサンドが気になるけど、やっぱりオムレツかなぁ」
                    <br />
                    「出来立てってなんか惹かれちゃうわ」
                  </p>
                  <p>
                    そんな会話をしていると、あっという間に出来立てトロトロのオムレツが完成だ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    人気メニューは京都市中央市場から仕入れた新鮮な野菜のサラダ、それからレストラン自慢のスパイスが効いたカレーなど。いつもの朝食スタイルで、妻は和食、私は洋食をチョイス。好きなものをそれぞれ好きなだけ、ちょっとずつシェアしたりするのもいい。
                  </p>
                  <p>
                    「今日はこれからどこにいく？」
                  </p>
                  <p>
                    朝食を楽しみつつ、ゆるりとプランを立てる。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_06.png",
              alt: "",
            }}
          />
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon06.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon06__sp.png",
              }}
            />
          </figure>
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_07.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    歴史好きの妻のすすめで、島原エリアへやってきた。
                    <br />
                    かつて芸事を極めた太夫や芸妓がいた置屋が軒を連ねていた花街である島原エリアでは、文化人たちや新撰組の志士たちが集まり宴席を繰り広げていたそうだ。
                  </p>
                  <p>
                    住宅街の中にひっそりと佇む島原大門の先へ、石畳の道が続く。
                    歩いているだけで歴史の威風と情緒を感じることができて、ひっそりとした静けさもあるから趣がある。
                  </p>
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article11/img_chapter03_08.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article11/img_chapter03_09.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                島原エリアには現在でもかつての花街の雰囲気を思わせる建物が保存されている。
              </>
            }
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">島原大門</h3>
            <p>
              住所：下京区西新屋敷町
            </p>
          </CQuoteBox>
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article11/icon07.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article11/icon07__sp.png",
              }}
            />
          </figure>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    少し小腹が空いてきたので早めのランチにすることに。
                    <br className="u_pc"/>
                    実は梅小路にはレトロ喫茶が点在している。その中でも60年近く営業をしているのが「喫茶ナス」。
                    ジャズ喫茶でマスターこだわりのカレーが食べられるということで、無類のカレー好きとしては、どうしても行ってみたかったお店だ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_10.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  洒落た看板がかかった扉を開く。ふわりとコーヒーの香りが漂う店内は“THE 純喫茶”とでも言おうか、レトロ感たっぷりの雰囲気だ。使い込まれたカウンターに喫茶店お馴染みの低めのテーブル。ゆったりと思い出話に浸りたくなるような優しい照明に、
                  さりげなく置かれたアートやポスター、ジャズレコードなど。どれをとっても、味がある。       
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_11.png",
              alt: "",
            }}
          />
          <div className="journal11Wrapper">
            <div className="journal11Img">
              <div className="journal11ImgItem">
                <AssetImage src="/assets/images/journal/article11/img_chapter03_12.png" alt="" />
              </div>
              <div className="journal11ImgItem vertical">
                <AssetImage src="/assets/images/journal/article11/img_chapter03_13.png" alt="" />
              </div>
            </div>
          </div>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    こだわりは一杯ずつ丁寧に淹れるサイフォンコーヒーとカレー。気さくなマスター那須さんにオーダーを伝える。
                  </p>
                  <p>
                    「ポークカツ・カレー、2つね」
                  </p>
                  <p>
                    そう言って店の奥へと消えていくマスター。しばらくすると、サックサクの衣をまとったポークカツとライスの土手に囲まれたスパイス香るカレーが並んだ一皿が運ばれてきた。聞くと8時間も時間をかけて毎日仕込んでいるそう。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article11/img_chapter03_14.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「んん！美味しい。甘い…？けど、後から辛さが効いてくる」
                    <br />
                    「コクがある味わいで、コーヒーにピッタリ！」
                  </p>
                  <p>
                    常連客がカウンターで静かにコーヒーとタバコを楽しむ中、軽快なジャズをBGMにアイスコーヒーを飲みながら、カレーを無心で頬張りペロリと完食。
                  </p>
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">喫茶ナス</h3>
            <p>
              住所：京都市下京区朱雀裏畑町32
              <br />
              電話：
              <a
                href="tel:075-313-8470">075-313-8470</a>
            </p>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  梅小路公園で触れた自然やワインショップ、喫茶店で言葉を交わした地元の人の笑顔、ホテルの部屋でくつろぎつつ飲んだあのワインの味…どれも忘れられない思い出になった。
                  <br />
                  夫婦ふたりなら、徒歩だけで巡る京都も悪くはない。快適でリラックスできて、観光の起点になるいいホテルも見つけられた。なかなかに充実した旅だった。次は寒い季節に再訪をしてみようか。
                  そしてまたあの喫茶店で温かいコーヒーをゆっくり飲むのもいい。
                  <br />
                  そんな余韻に浸りつつ、旅を締めくくる。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2024年7月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_tac u_fs20 u_mbSmall">ザ ロイヤルパークホテル <br className="u_sp"/>京都梅小路 おすすめプラン</p>
          <CQuoteBox>
            <h3 className="c_headingLv3 u_tac">
            【夏の風物詩2024】
            <br />
            高雄「もみぢ家」納涼
            <br className="u_sp"/>
            川床料理堪能プラン（夕・朝食付）
            </h3>
            <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "プラン詳細",
                color: "bgBlack",
                link: {
                  href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_theumekoji&plan_groupcd=KD1&form=jp",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
          </CQuoteBox>

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパークホテル 京都梅小路</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the/kyotoumekoji/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
